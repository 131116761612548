.catalogue-container img {
  height: 100%;
  width: 100%;
  transition: 5s all ease-in-out;
}

@media (min-width: 1500px) and (max-width: 2000px) {
  .catalogue-container img {
    margin-top: -100px;
  }
}

@media (min-width: 2000px) and (max-width: 2200px) {
  .catalogue-container img {
    margin-top: -100px;
  }
}

@media (min-width: 2200px) {
  .catalogue-container img {
    margin-top: -100px;
  }
}

@media (max-width: 800px) {
  .catalogue-container img {
    margin-top: -100px;
  }
}

@media (max-width: 600px) {
  .catalogue-container img {
    margin-top: 0px;
  }
}

.catalogue-container:hover img {
  transform: scale(1.05);
}
.catalogue-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  /* margin-right: 60px;
  margin-left: 60px; */
}
.catalogue-container h1 {
  margin-top: 0px !important;
  text-align: left;
}
.catalogue-container .imgBox {
  display: inline-block;
  overflow: hidden !important;
  position: relative;
  border-radius: 0px;
  min-width: 400px;
  max-height: 400px;
  margin-left: 60px;
  width: 30%;
}
@media (max-width: 800px) {
  .catalogue-container {
    flex-wrap: wrap;
    text-align: center;
    /* margin-right: 0px;
    margin-left: 0px; */
  }
  .catalogue-container .imgBox {
    margin-right: 60px;
    width: 100%;
  }
  .catalogue-container .text-box {
    margin-top: 50px;
    width: 100% !important;
  }
  .catalogue-container h1 {
    text-align: center;
    margin-top: 0px;
  }
}
.catalogue-container .text-box {
  margin-right: 60px;
  margin-left: 60px;
  width: 50%;
}
.catalogue-container .text-box p {
  font-size: 20px;
}
.catalogue-container button {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: white;
  border: 1px solid white !important;
  background: white !important;
  border-radius: 0px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.15rem;
  cursor: pointer;
  width: 200px;
}
.catalogue-container button {
  background: black !important;
  border: 1px solid black !important;
  width: 250px;
}
.catalogue-container button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  background: rgba(255, 255, 255, 1) !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.catalogue-container button:hover,
button:focus,
button:active {
  color: black;
  border: 1px solid black;
}
.catalogue-container button:hover::before,
button:focus::before,
button:active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
