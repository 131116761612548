@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth&family=Lato&display=swap');

body {
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

h1 {
  margin-left: 0 !important;
}

.slider-content button {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: white;
  border: 1px solid white !important;
  background: white !important;
  border-radius: 35px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 20px 40px;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.15rem;
  cursor: pointer;
  width: 350px;
}

.slider-content button {
  color: black;
  background: rgba(255, 255, 255, 1) !important;
}

.slider-content button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  background: rgba(255, 255, 255, 1) !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.slider-content button::before {
  background: lightgrey !important;
  border: lightgrey !important;
  border-radius: 35px !important;
}

.slider-content button:hover,
button:focus,
button:active {
  color: rgb(100, 100, 100);
  border: 2px solid lightgrey !important;
  border-radius: 35px;
}

.slider-content button:hover,
button:focus,
button:active {
  color: black;
  border: 1px solid lightgrey !important;
  border-radius: 35px !important;
}

.slider-content button:hover::before,
button:focus::before,
button:active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  margin-top: 165px;
  position: relative;
  height: 80vh;
  overflow: hidden;
  padding-top: 0px;
  z-index: 0;
}

@media (min-width: 1650px) {
  .slider-wrapper {
    height: 90vh;
  }
}

.slide {
  height: 90vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  ); */
  /* background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)); */
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("../../images/arrow-right.svg") no-repeat center center / 20px;
  background-color: #222222;
  opacity: 0.5;
  border-radius: 30px;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  margin-left: 20px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  opacity: 1;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

/* .previousButton:hover {
  left: -10px;
} */

.nextButton {
  right: 0;
}

/* .nextButton:hover {
  right: -10px;
} */

.slider-desktop {
  text-align: left;
}

.slider-desktop .inner {
  padding: 0 60px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  letter-spacing: 0.2rem;
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: black;
  font-size: 72px;
  max-width: 30%;
  line-height: 1;
  font-family: 'Amaranth', sans-serif;
}

.slider-content .inner p {
  color: white;
  font-size: 50px;
  line-height: 1.5;
  margin: 20px 0 130px 0;
  max-width: 30%;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 1px 1px 2px grey;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 42px;
  }
}

@media (max-width: 1750px) {
  .slider-content .inner h1 {
    font-size: 60px;
  }
  .slider-content .inner p {
    font-size: 50px;
  }
  .slider-content button {
    border-radius: 35px;
    padding: 20px 40px;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.15rem;
    cursor: pointer;
    width: 300px;
  }
}

@media (max-width: 1575px) {
  .slider-content .inner h1 {
    font-size: 50px;
  }
  .slider-content .inner p {
    font-size: 40px;
  }
}
@media (max-width: 1350px) {
  .slider-content .inner h1 {
    font-size: 50px;
  }
  .slider-content .inner p {
    font-size: 40px;
    margin: 0px 0 90px 0;
  }
  .slider-content button {
    border-radius: 35px;
    padding: 15px 40px;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 0.15rem;
    cursor: pointer;
    width: 250px;
  }
}

@media (max-width: 1220px) {
  .slider-content .inner h1 {
    max-width: 28%;
  }
  .slider-content .inner p {
    max-width: 28%;
  }
}

@media (max-width: 1150px) {
  .slider-content .inner h1 {
    font-size: 50px;
    color: white;
  }
  .slider-content .inner p {
    font-size: 32px;
    color: white;
  }
}

@media (max-width: 1000px) {
  .slider-content .inner h1 {
    max-width: 40%;
  }
  .slider-content .inner p {
    max-width: 40%;
  }
  .slider-content button {
    border-radius: 35px;
    padding: 15px 15px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.15rem;
    cursor: pointer;
    width: 200px;
  }
}

@media (min-width: 801px) {
  .slider-mobile {
    display: none;
  }
}

@media (max-width: 800px) {
  .slider-content .inner h1 {
    max-width: 90%;
  }
  .slider-content .inner p {
    max-width: 100%;
    margin: 0px 0px 10px 0px;
    padding: 0% 5%;
  }
  .slider-desktop {
    display: none;
  }

  .slider-mobile {
    margin-top: 20%;
    height: 100vh;
  }

  .slider-mobile .inner {
  padding: 0 0px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 75%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  }
}

@media (max-width: 800px) {
  .slider-content .inner {
    top: 70%;
  }

  .slider-content button {
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .slider-content .inner {
    top: 70%;
  }

  .slider-content button {
    margin-top: 20px;
  }
}

@media (max-width: 560px) {
  .slider-content .inner {
    top: 70%;
  }

  .slider-content button {
    margin-top: 30px;
  }
}

@media (max-width: 500px) {
  .slider-content .inner {
    top: 70%;
  }

  .slider-content button {
    margin-top: 30px;
  }
}


@media (max-width: 450px) {
  .slider-content .inner {
    top: 73%;
  }
  .slider-content .inner p {
    font-size: 30px;
  }
  .slider-content button {
    border-radius: 35px;
    padding: 15px 10px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.15rem;
    cursor: pointer;
    width: 200px;
    margin-top: 20px;
  }

}