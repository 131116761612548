.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-container h1 {
  margin-top: 50px;
  text-align: center;
}

.aboutPage .text-container img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.about-picture {
  border-style: none;
  margin-top: 25vh;
  /* max-height: 500px;
  max-width: 500px; */
}

.text-container {
  width: 80%;
  max-width: 800px;
  line-height: 1.6;
  margin-bottom: 50px;
}

.text-container p {
  font-size: 16px;
}

@media (max-width: 800px) {
  .aboutPage .text-container {
    width: 100%;
  }
  .text-container-body {
    margin-right: 60px;
    margin-left: 60px;
  }
}
