.newCustomer-container {
  text-align: center;
  margin-top: 60px;
  width: 100%;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}

.newCustomer-background {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: url("../../images/product_pictures/BDA22S.jpg");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.newCustomer-container .text-box {
  background: white;
  flex: 0 0 650px;
  padding: 40px;
  z-index: 2;
}

@media (max-width: 800px) {
  .newCustomer-container .text-box {
    flex: 0 0 350px;
  }
}

.newCustomer-container a {
  color: black !important;
  position: relative;
  display: inline-block;
  font-size: 20px;
}

.newCustomer-container .text-box h3 {
  font-size: 2em;
}

.newCustomer-container .text-box p {
  font-size: 20px;
}

.newCustomer-container a::after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  background: #0413ac;
  transition: 0.4s ease;
  width: 100%;
}

.newCustomer-container a:hover::after {
  width: 0%;
}
