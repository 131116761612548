.contact-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  margin-right: 60px;
  margin-left: 60px;
  background: white;
  margin-top: 50px;
  margin-bottom: 60px;
}

.splash-map {
  margin-top: 0;
  max-width: 50%;
}

.contact-container button {
  margin-top: 0px;
  background: #c1d8f0;
  margin-right: 40px;
}

.contact-container h3 {
  text-align: left;
  font-size: 2em;
  margin-top: 0px;
}

.contact-container p {
  font-size: 16px;
}

.contact-container .contact-box {
  max-width: 600px;
  margin-right: 40px;
  margin-left: 40px;
}

.contact-container button {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: white;
  border: 1px solid white !important;
  background: white !important;
  border-radius: 0px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.15rem;
  cursor: pointer;
  width: 200px;
}

.contact-container button {
  background: black !important;
  border: 1px solid black !important;
  width: 225px;
  margin-top: 10px;
}

.contact-container button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  background: rgba(255, 255, 255, 1) !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.email a:focus,
.email a:hover,
.email a {
  text-decoration: none;
  color: black;
}

.contact-container button:hover,
button:focus,
button:active {
  color: black;
  border: 1px solid black;
}

.contact-container button:hover::before,
button:focus::before,
button:active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media (max-width: 1000px) {
  .contact-container {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .splash-map {
    margin-left: 40px;
    align-items: center;
    max-width: 100%;
    margin-right: 40px;
  }
  .contact-container h3 {
    margin-top: 20px;
    text-align: center;
  }
  .contact-container .contact-box {
    margin-bottom: 40px;
  }
  .contact-container p {
    font-size: 16px;
  }
  .contact-box {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .direction-button {
    margin-left: auto;
  }
}
