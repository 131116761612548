.NavbarItems {
  background: rgb(255, 255, 255, 0.95);
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.NavbarItems img {
  height: 250%;
  width: 250%;
}

.social-icon svg {
  width: 60px;
}

/* .navbar-logo {
} */

.logo {
  max-width: 250px;
  margin-left: 1rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 25px;
  list-style: none;
  text-align: center;
  width: 85vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-links {
  text-decoration: none;
  padding: 0;
  color: black;
  font-weight: 200;
  font-family: "Nunito Sans", sans-serif;
  font-size: 25px;
  letter-spacing: 0.05rem;
  position: relative;
}

li a:hover {
  color: black;
}

li a::after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  background: #0413ac;
  transition: 0.4s ease;
  width: 0;
}

li a:hover::after {
  width: 100%;
  left: 0;
}

.menu-icon {
  display: none;
}

.social-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    z-index: 1000;
    background: rgb(255, 255, 255, 1);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    height: 100vh;
    position: absolute;
    top: 140px;
    left: -100%;
    opacity: 1;
    transition: left 0.5s ease-in-out;
    margin-right: 0;
    text-align: center;
    z-index: 1;
    background: white;
    justify-content: start;
  }

  .logo {
    margin-left: 0;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: left 0.5s ease-in-out;
    z-index: 1;
    overflow-y: scroll;
    padding-top: 0;
    height: 100vh;
  }

  .nav-links {
    padding-top: 0.5rem;
    display: table;
    width: 100%;
    text-align: center;
    font-size: 40px;
  }

  li a:hover::after {
    width: 0%;
    left: 0;
  }

  .navbar-logo {
    position: absolute;
    padding-left: auto;
  }

  .menu-icon {
    display: block;
    position: absolute;
    /* top: 0; */
    right: 0;
    transform: translate(-100%, 10%);
    font-size: 2.5rem;
    cursor: pointer;
  }

  .social-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .social-icon .youtube {
    margin-left: 20px;
    margin-right: 20px;
  }

  /* .social-icon .youtube svg {
    width: 50px;
  } */

  /* 
  .fa-times {
    font-size: 2.5rem;
  }

  .fa-bars {
    font-size: 2.5rem;
  } */

  .social {
    display: visible;
  }
}

@media screen and (max-width: 500px) {
  .NavbarItems img {
    padding: 10%;
  }
}

@media screen and (max-height: 700px) {
  .nav-menu.active {
    height: 300%;
  }
}

@media screen and (max-height: 600px) {
  .nav-menu.active {
    height: 250%;
  }
}

@media screen and (max-height: 500px) {
  .nav-menu.active {
    height: 200%;
  }
}

@media screen and (max-height: 400px) {
  .nav-menu.active {
    height: 150%;
  }
}

@media screen and (max-height: 300px) {
  .nav-menu.active {
    height: 100%;
  }
}
