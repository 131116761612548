/* .instagram-container {
  margin-left: 60px;
  margin-right: 60px;
} */

.instagram-header {
  text-align: center;
  padding-top: 25px;
}

.instagram-feed {
  display: inline-flex;
  justify-content: center;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 20px;
  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.instagram-photo {
  /* display: block; */
  height: 264px;
  width: 264px;
  margin: 10px;
}

.instagram-photo-container {
  position: relative;
  flex: 0 0 auto;
  margin-right: 3px;
  width: 300px;
}

.instagram-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 264px;
  height: 264px;
  margin: 10px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.25s;
}

.instagram-overlay:hover {
  opacity: 1;
}

.instagram-title {
  margin-top: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instagram-date {
  margin-top: auto;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
