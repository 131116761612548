@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.App {
  background-color: #fafafa;
}