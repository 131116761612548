.video-header {
  text-align: center;
}

.video-container {
  margin-right: 60px;
  margin-left: 60px;
}

@media (max-width: 800px) {
  .video-container {
    margin-right: 0px;
    margin-left: 0px;
  }
}
