.TermsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  margin-top: 50px;
  text-align: center;
}

.text-container img {
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.terms-picture {
  border-style: none;
  margin-top: 150px;
}

.terms-div {
  padding-bottom: 20px;
}

.text-container {
  width: 80%;
  line-height: 1.6;
  margin-bottom: 50px;
}

.terms-div h3 {
  font-size: 20px;
}
